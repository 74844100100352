//
//
//
//
//
//

import layoutNavigation from "~/components/01_templates/LayoutNavigation.vue";

export default {
  name: "Default",
  components: {
    layoutNavigation
  }
};
