//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "RichtextLink",
  props: {
    attrs: {
      type: Object,
      required: true
    }
  },
  setup({ attrs }) {
    const linkType = ref(attrs.linktype);
    const href = ref(attrs.href);
    const target = ref(attrs.target);
    return {
      linkType,
      href,
      target
    };
  },
  methods: {}
});
