import Vue from 'vue'
import VueRichTextRenderer from '@marvr/storyblok-rich-text-vue-renderer'
import {
  Mark
} from '@marvr/storyblok-rich-text-types';
import RichtextLink from '@/components/06_utils/RichtextLink.vue'
import RichtextImage from '@/components/06_utils/RichtextImage.vue'

Vue.use(VueRichTextRenderer, {
  resolvers: {
    components: {
      RichtextLink,
      RichtextImage,
    },
    marks: {
      [Mark.LINK]: RichtextLink
    }
  },
})
