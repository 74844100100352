import server from "~/helper/server.js";
import Vue from "vue";

import {
  VctrApi
} from "@/helper/vectaryApi.js";
export const state = () => ({
  navLinks: [],
  footer: {},
  shortLinks: [],
  vectaryConfigOpen: false,
  vectaryInstanceReady: false,
  vectaryData: [],
  vectaryGroups: [],
  vectaryMaterialStyles: [],
  vectaryActiveElements: []
})

export const mutations = {
  setNavigation: (state, navigation) => (state.navLinks = navigation),
  setFooter: (state, footer) => (state.footer = footer),
  getShortLinks: (state, shortLinks) => (state.shortLinks = shortLinks),
  setMaterialStyles: (state, vectary) => {
    state.vectaryMaterialStyles.push(
      vectary
    )
  },
  setVectaryData: (state, vectary) => {
    state.vectaryInstanceReady = true;
    state.vectaryData = vectary.data;
    // console.log(state.vectaryData)

    state.vectaryGroups = vectary.groups.map((group) => {

      // set active mesh
      const active = vectary.active.filter((item) => group.childrenNames.includes(item.child))[0].child
      group.activeChild = active;

      // set active materials group
      const materials = vectary.data.filter((item) => item.type === "group" && item.parentName === active && item.name.startsWith("material"));
      if (materials.length > 0) {
        group.activeMaterials = materials[0].childrenNames;
        materials[0].childrenNames.forEach((element) => {
          window.vctrApi.setVisibility(element, false, false);
        })
      }


      // set active material
      const material = vectary.data.filter((item) => item.type === "mesh" && item.parentName === active);
      if (material.length > 0) {
        group.activeMaterial = material[0].name;
        if (materials.length > 0) {
          console.log(material[0].name);

          group.activeMaterials.unshift(material[0].name);
        }

      }

      // set active camera
      const camera = state.vectaryData.filter((item) => item.type === "camera");

      if (camera.length > 0) {
        console.log(camera[0].name);
        window.vctrApi.switchViewAsync(camera[0].name);
      }

      return group
    })

    state.vectaryActiveElements = vectary.active
  },
  hideMaterial: (state) => {
    const materials = state.vectaryData.filter((item) => item.type === "group" && item.name.startsWith("material"));
    console.log(materials);
    materials.forEach((item) => {
      window.vctrApi.setVisibility(item.name, false, false);
    })

  },
  setVectaryActiveElements: (state, payload) => {
    state.vectaryGroups = state.vectaryGroups.map((group) => {
      if (group.name === payload.parent) {
        group.activeChild = payload.child;

        /*
   const materials = state.vectaryData.filter((item) => item.type === "group" && item.parentName === payload.child && item.name.startsWith("material"));
   if (materials.length > 0) {
     group.activeMaterials = materials[0].childrenNames;
   }

   // set active material
 
   const material = state.vectaryData.filter((item) => item.type === "mesh" && item.parentName === payload.child && !item.name.startsWith("meshes"));
   console.log(payload.child);
   if (material.length > 0) {
     if (!group.activeMaterials.includes(material[0].name)) {
       group.activeMaterials.unshift(material[0].name);
     }
     group.activeMaterial = material[0].name;
     const mat = state.vectaryData.filter(
       item => item.type === "mesh" && item.name === material[0].name
     )[0].material;

     window.vctrApi.setMaterial(material[0].name, mat);
   }*/

        const multiplemeshes = state.vectaryData.filter((item) => item.type === "mesh" && item.parentName === payload.child && item.name.startsWith("meshes"));
        if (multiplemeshes.length > 0) {
          if (!group.activeMaterials.includes(multiplemeshes[0].name)) {
            group.activeMaterials.unshift(multiplemeshes[0].name);
          }
          group.activeMaterial = multiplemeshes[0].name;
          const mat = state.vectaryData.filter(
            item => item.type === "mesh" && item.name === multiplemeshes[0].name
          )[0].multiplemeshes;

          window.vctrApi.setMaterial(multiplemeshes[0].name, mat);
        }


        // set active camera
        const camera = state.vectaryData.filter((item) => item.type === "camera");
        if (camera.length > 0 && payload.parent === "View") {
          window.vctrApi.switchViewAsync(payload.child);
        }
      }
      return group
    })
  },
  setVectaryMaterial: (state, payload) => {
    state.vectaryGroups = state.vectaryGroups.map((group) => {
      if (group.name === payload.group) {
        group.activeMaterial = payload.mat;
      }
      return group
    })
  },
  setVectaryCamera: (state, payload) => {
    state.vectaryGroups = state.vectaryGroups.map((group) => {
      if (group.name === payload.group) {
        group.activeCamera = payload.mat;
      }
      return group
    })
  },
  setVectaryConfigOpen: (state) => {
    state.vectaryConfigOpen = !state.vectaryConfigOpen;
  },
}

export const actions = {
  async initVectary({
    commit
  }, vectaryID) {
    let boom = 1

    function errHandler(err) {
      if (boom < 1) {
        console.log("API error", err);
      }
      boom = 2
    }

    window.vctrApi = new VctrApi(
      vectaryID,
      errHandler
    );
    console.log('$store: ' + vectaryID);
    async function onReady() {
      const allObjects = await vctrApi.getObjects();

      // Sets 
      const groups = allObjects.filter((item) => item.type === "group" && item.parentName === vectaryID);
      const active = []
      groups.forEach((item) => {
        item.childrenNames.forEach((element, index) => {
          if (index > 0) {
            window.vctrApi.setVisibility(element, false, false);
          } else {
            active.push({
              parent: item.name,
              child: element
            })
          }
        })
      })



      const allMaterials = await vctrApi.getMaterials();
      allMaterials.forEach(async (item) => {
        let myMaterialPropsm = await vctrApi.getMaterialProperties(item.name);
        commit("setMaterialStyles",
          myMaterialPropsm
        );
      })


      commit("setVectaryData", {
        data: allObjects,
        groups: groups,
        active: active,
        id: vectaryID,
      });

      // const allMaterials = await vctrApi.getMaterials();
      // const allMeshes = await vctrApi.getMeshes();
      // addOptionsToSelector(allMaterials.map(mat => mat.name), materialSelector);
      //  addOptionsToSelector(allMeshes.map(mesh => mesh.name), meshSelector);
    }
    try {
      await vctrApi.init();
    } catch (e) {
      errHandler(e);
    }
    onReady();
  },

  async editorData({
    commit
  }) {
    if (process.client) {
      const version = "draft";
      const navigation = await server.storyblokApi(this, version, "global/navigation");
      const footer = await server.storyblokApi(this, version, "global/footer");

      await commit('setNavigation', navigation.content.links);
      await commit('setFooter', footer);
    }
  },
  async nuxtServerInit({
    commit
  }, context) {

    const version = server.getVersion(context);

    const navigation = await server.storyblokApi(context.app, version, "global/navigation", context);
    const footer = await server.storyblokApi(context.app, version, "global/footer", context);

    await commit('setNavigation', navigation.content.links);
    await commit('setFooter', footer);
  },
}
