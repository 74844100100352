//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "RichtextImage",
  props: {
    body: {
      type: Object,
      require: true
    }
  }
};
