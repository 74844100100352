module.exports = {
  checkGenerate(slug) {
    return slug === 'home' ||
      slug.includes("global/")
  },
  getVersion(context) {
    return context.query._storyblok || context.isDev ? "draft" : "published";
  },
  getSlug(context) {
    const path = context.route.path;
    return path == "/" || path == "" ? context.$config.frontPage : path;
  },
  storyblokApi(api, version, fullSlug, context) {
    return api.$storyapi
      .get(`cdn/stories/${fullSlug}`, {
        version: version
      })
      .then(res => {

        return res.data.story;
      })
      .catch(res => {
        if (!res.response) {

          console.error(res);
          context.error({
            statusCode: 404,
            message: "Failed to receive content form api"
          });
        } else {
          console.error(res.response.data);
          context.error({
            statusCode: 404,
            message: res.response.data
          });
        }
      });
  },
  transformImage(image, option) {
    if (!image) return "";
    if (!option) return "";

    let imageService = "//img2.storyblok.com/";
    let path = image.replace("//a.storyblok.com", "");
    return imageService + option + path;
  },
  pageHead(story) {
    return {
      title: story.content.seo.title || story.name,
      meta: [{
        key: "description",
        name: "description",
        content: story.content.seo.description || 'Linking everything thats next'
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:url",
        content: story.full_slug
      },
      {
        property: "og:title",
        content: story.content.seo.title || story.name
      },
      {
        property: "og:description",
        content: story.content.seo.description || 'Linking everything thats next'
      },
      {
        property: "og:image",
        content: this.transformImage(
          story.content.seoImage.filename,
          "1200x630" + "/smart/filters:format(jpg)"
        )
      },
      {
        property: "twitter:card",
        content: "summary_large_image"
      },
      {
        property: "twitter:url",
        content: story.full_slug
      },
      {
        property: "twitter:title",
        content: story.content.seo.title || story.name
      },
      {
        property: "twitter:description",
        content: story.content.seo.description || 'Linking everything thats next'
      },
      {
        property: "twitter:image",
        content: this.transformImage(
          story.content.seoImage.filename,
          "1200x630" + "/smart/filters:format(jpg)"
        )
      },
      {
        property: "twitter:image:alt",
        content: story.content.seoImage.alt
      }
      ]
    };
  },
}
