//
//
//
//
//
//
//
//

export default {
  name: "error",
  props: ["error"],
  head() {
    return {
      title: "Page not found"
    };
  },
  data() {
    return {};
  },
  computed: {
    errData() {
      return (
        this.error || {
          statusCode: 404
        }
      );
    },
    errTitle() {
      switch (this.error.statusCode) {
        case 404:
          return "Page not found";
        default:
          return "An error occurred";
      }
    },
    errBody() {
      switch (this.error.statusCode) {
        case 404:
          return "We cannot seem to find the page you were looking for. The link you followed is probably broken or the page has been removed.";
        default:
          return "Please reload or try again later.";
      }
    }
  },
  methods: {
    goToHome() {
      window.location.href = window.origin;
    }
  }
};
