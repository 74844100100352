//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const Crops = ["300", "600", "960", "1024", "1280", "1440", "1600", "1760"];
export default {
  name: "LazyPicture",
  props: {
    filename: {
      type: String,
      default: ""
    },
    alt: {
      type: String,
      default: ""
    },
    focus: {
      type: String,
      default: ""
    },
    loading: {
      type: String,
      default: "lazy"
    },
    sizes: {
      type: String
    },
    ratioW: {
      type: Number,
      default: ""
    },
    ratioH: {
      type: Number,
      default: "0"
    },
    inlineStyle: {
      type: Object
    },
    classes: [String, Object]
  },
  data() {
    return {
      currentSrc: false,
      loaded: false,
      done: false,
      isCached: false
    };
  },
  created() {
    this.CROPS = Crops;
  },
  mounted() {
    if (!this.filename) {
      return false;
    }
    // check if image is cached
    this.$nextTick(() => {
      this.currentSrc = this.$el.querySelector("img.actual").currentSrc;
      const image = new Image();
      image.src = this.currentSrc;
      this.isCached = image.complete;
      if (this.isCached) {
        this.done = true; // for safari! @load is not called if img is already loaded
      }
    });
  },
  watch: {
    loaded() {
      if (this.$refs.placeholder) {
        const timeout = Math.round(Math.random() * (700 - 500) + 500);
        setTimeout(() => {
          this.animateOutPlaceholder(this.$refs.placeholder, 1);
        }, timeout);
      } else {
        this.done = true;
      }
    },
    done() {
      this.$emit("done");
    }
  },
  methods: {
    onImgLoad() {
      this.loaded = true;
    },
    animateOutPlaceholder(el, i) {
      if (!el) {
        return false;
      }
      el.src = this.transformedImage("jpeg", this.ratioW);
      el.onload = () => {
        this.done = true;
      };
    },
    transformedImage(format, width) {
      const imageService = "https://img2.storyblok.com/";
      const option = format ? `/smart/filters:format(${format})` : "";
      const path = this.filename.replace("https://a.storyblok.com", "");
      if (width) {
        let height = Math.round(width * this.imageRatio);
        if (!this.ratioH) {
          height = 0;
        }
        return `${imageService}${width}x${height}${option}${path}`;
      } else {
        return this.CROPS.map(width => {
          let height = Math.round(width * this.imageRatio);
          if (!this.ratioH) {
            height = 0;
          }
          return `${imageService}${width}x${height}${option}${path} ${width}w`;
        }).join(",");
      }
    }
  },
  computed: {
    showPlaceholder() {
      return this.loading === "lazy" && !this.isCached && !this.done;
    },
    imageRatio() {
      return this.ratioH / this.ratioW;
    },
    fileExtension() {
      const regex = /(?:\.([^.]+))?$/;
      return regex.exec(this.filename)[1];
    }
  }
};
